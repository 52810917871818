import React from 'react';

export default () => {
    return(
        <svg width="89px" height="72px" viewBox="0 0 89 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="twitterColor" transform="translate(-2580.000000, -5666.000000)" fill="#D2D2D2" fillRule="nonzero">
                    <g id="Twitter_Logo_White" transform="translate(2580.000000, 5666.000000)">
                        <path d="M27.7482353,71.8552941 C61.0447059,71.8552941 79.2564706,44.2694118 79.2564706,20.3470588 C79.2564706,19.5635294 79.2564706,18.7835294 79.2035294,18.0070588 C82.7464638,15.4443914 85.8047841,12.2713391 88.2352941,8.63647059 C84.931338,10.1004769 81.4264564,11.0605715 77.8376471,11.4847059 C81.6167435,9.22228976 84.4451418,5.66390118 85.7964706,1.47176471 C82.2429088,3.58043047 78.3552215,5.06651294 74.3011765,5.86588235 C68.689704,-0.100961365 59.7731273,-1.56136736 52.5513505,2.30357612 C45.3295736,6.16851988 41.5986176,14.3976201 43.4505882,22.3764706 C28.894926,21.6467629 15.333444,14.7717293 6.14117647,3.46235294 C1.33631562,11.7340401 3.79054546,22.3159747 11.7458824,27.6282353 C8.86496894,27.54285 6.04686836,26.7656936 3.52941176,25.3623529 C3.52941176,25.4364706 3.52941176,25.5141176 3.52941176,25.5917647 C3.53176987,34.2091518 9.60618176,41.6313007 18.0529412,43.3376471 C15.3877751,44.0644941 12.5914609,44.1707446 9.87882353,43.6482353 C12.2504044,51.0226687 19.0467339,56.0745314 26.7917647,56.22 C20.3814176,61.2579949 12.4625629,63.9929213 4.30941176,63.9847059 C2.86907142,63.9819406 1.43013201,63.8947324 0,63.7235294 C8.27871512,69.0362704 17.9114591,71.8542949 27.7482353,71.8411765" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
};